import { useMemo } from 'react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/navigation'
import { ChevronRight } from 'lucide-react'
import { useLocale, useTranslations } from 'next-intl'
import { zodResolver } from '@hookform/resolvers/zod'

import { FetchAdapter } from '@/infra/http'
import { SonnerAdapter } from '@/infra/toast'
import { DateFnsAdapter } from '@/infra/date'
import { SignInUseCase } from '@/data/usecases/auth'
import { GetUserByIdUseCase } from '@/data/usecases/users'
import { Locale, UserCookiePayload } from '@/domain/models'
import { Button, Input } from '@/presentation/components/shared'
import { AUTHENTICATED_USER_COOKIE_KEY } from '@/presentation/constants'
import {
  getServerErrorMessage,
  revalidatePage,
  setCookie,
} from '@/presentation/actions'

import styles from './styles.module.scss'

interface LoginFormProps {
  callback(user: UserCookiePayload): Promise<void>
}

const LoginUserSchema = z.object({
  email: z
    .string()
    .email({ message: 'errors.client.users.login-user-schema.email.email' }),
  password: z.string(),
})

export function LoginForm({ callback }: LoginFormProps) {
  const t = useTranslations('components.pages.accounts.login.LoginForm')
  const rootTranslations = useTranslations()
  const locale = useLocale() as Locale
  const router = useRouter()

  const { add } = new DateFnsAdapter()
  const { toast } = new SonnerAdapter()

  const { register, handleSubmit, formState } = useForm<
    z.infer<typeof LoginUserSchema>
  >({
    resolver: zodResolver(LoginUserSchema),
  })

  const errors = useMemo((): Record<
    keyof z.infer<typeof LoginUserSchema>,
    string | undefined
  > => {
    const translateError = (
      error: keyof z.infer<typeof LoginUserSchema>,
    ): string | undefined => {
      const field = formState.errors[error]
      return field ? rootTranslations(field.message as any) : undefined
    }

    return {
      email: translateError('email'),
      password: translateError('password'),
    }
  }, [formState.errors])

  const onSubmit = async (data: z.infer<typeof LoginUserSchema>) => {
    const http = new FetchAdapter()

    const signInUseCase = new SignInUseCase(http)
    const sign = await signInUseCase.execute({
      email: data.email,
      password: data.password,
    })

    if (!sign.data) {
      const message = await getServerErrorMessage(
        `users.${sign.error?.code}`,
        locale,
      )

      toast({
        status: 'error',
        text: message,
      })
      return
    }

    const setAuthenticatedUserCookie = async (
      data:
        | UserCookiePayload
        | Omit<UserCookiePayload, 'name' | 'email' | 'role'>,
    ) => {
      await setCookie({
        name: AUTHENTICATED_USER_COOKIE_KEY,
        value: data,
        options: {
          expires: add(new Date(), { seconds: sign.data?.expiresIn }),
        },
      })
    }

    await setAuthenticatedUserCookie({
      userId: sign.data.userId,
      accessToken: sign.data.accessToken,
      refreshToken: sign.data.refreshToken,
      expiredAt: add(new Date(), { seconds: sign.data.expiresIn }),
    })

    const getUserByIdUseCase = new GetUserByIdUseCase(http)
    const user = await getUserByIdUseCase.execute({
      userId: sign.data.userId,
      accessToken: sign.data.accessToken,
    })

    if (!user.data) {
      const message = await getServerErrorMessage(
        `users.${user.error?.code}`,
        locale,
      )

      toast({
        status: 'error',
        text: message,
      })
      return
    }

    const authenticatedUserCookiePayload: UserCookiePayload = {
      userId: sign.data.userId,
      name: user.data.name,
      email: user.data.email,
      role: user.data.role,
      accessToken: sign.data.accessToken,
      refreshToken: sign.data.refreshToken,
      expiredAt: add(new Date(), { months: 1 }),
    }

    await setAuthenticatedUserCookie(authenticatedUserCookiePayload)

    await revalidatePage('/')

    if (user.data.role === 'influencer') {
      await callback(authenticatedUserCookiePayload)
      return
    }

    router.push('/')
  }

  return (
    <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Input
          label={t('EMAIL_INPUT_LABEL')}
          type="email"
          placeholder={t('EMAIL_INPUT_PLACEHOLDER')}
          error={errors.email}
          {...register('email')}
        />
        <Input
          label={t('PASSWORD_INPUT_LABEL')}
          type="password"
          placeholder="********"
          autoComplete="off"
          error={errors.password}
          {...register('password')}
        />
      </div>

      <footer>
        <Button.Root type="submit" isLoading={formState.isSubmitting}>
          <Button.Text>{t('SUBMIT_BUTTON_TEXT')}</Button.Text>
          <ChevronRight size={16} color="#FFFFFF" />
        </Button.Root>
      </footer>
    </form>
  )
}
