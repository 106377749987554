import { UserRole } from '@/domain/entities'
import { HttpClient } from '@/data/protocols/http'
import { IGetUserByIdUseCase } from '@/domain/usecases/users'
import {
  // UseCaseAuthHandlerDecorator,
  UseCaseErrorHandlerDecorator,
} from '@/presentation/decorators'

type SignUpReturn = {
  user_id: string
  name: string
  email: string
  email_verified: boolean
  role: UserRole
  created_at: Date
  bio?: string
  photo_url?: string
  auth_token?: string
  deleted_at?: Date
  approved_at?: Date
}

@UseCaseErrorHandlerDecorator()
export class GetUserByIdUseCase implements IGetUserByIdUseCase {
  constructor(private readonly http: HttpClient) {}

  async execute(
    input: IGetUserByIdUseCase.Input,
  ): Promise<HttpClient.Output<IGetUserByIdUseCase.Output>> {
    const response = await this.http.on<SignUpReturn>({
      url: process.env.BASE_API_URL + `/users-service/users/${input.userId}`,
      method: 'GET',
      headers: {
        Authorization: input.accessToken,
      },
    })

    if (response.error || !response.data) {
      return {
        status: response.status,
        error: response.error,
      }
    }

    return {
      status: response.status,
      data: {
        userId: response.data.user_id,
        name: response.data.name,
        email: response.data.email,
        photoUrl: response.data.photo_url,
        role: response.data.role,
        createdAt: response.data.created_at,
        deletedAt: response.data.deleted_at,
        approvedAt: response.data.approved_at,
      },
    }
  }
}
