import { User } from '@/domain/entities'
import { HttpClient } from '@/data/protocols/http'
import { ICreateUserUseCase } from '@/domain/usecases/users'
import { UseCaseErrorHandlerDecorator } from '@/presentation/decorators'

@UseCaseErrorHandlerDecorator()
export class CreateUserUseCase implements ICreateUserUseCase {
  constructor(private readonly http: HttpClient) {}

  async execute(
    input: ICreateUserUseCase.Input,
  ): Promise<HttpClient.Output<ICreateUserUseCase.Output>> {
    const response = await this.http.on<User>({
      url: 'http://localhost:3001/users',
      method: 'POST',
      body: {
        name: input.name,
        email: input.email,
        password: input.password,
        photoUrl: input.photoUrl,
        role: input.role,
      },
    })

    return response
  }
}
