import { HttpClient } from '@/data/protocols/http'
import { IListUsersUseCase } from '@/domain/usecases/users'
import { UseCaseErrorHandlerDecorator } from '@/presentation/decorators'

@UseCaseErrorHandlerDecorator()
export class ListUsersUseCase implements IListUsersUseCase {
  constructor(private readonly http: HttpClient) {}

  async execute(
    input: IListUsersUseCase.Input,
  ): Promise<HttpClient.Output<IListUsersUseCase.Output>> {
    const response = await this.http.on<IListUsersUseCase.Output>({
      url: process.env.NEXT_PUBLIC_BASE_API_URL + '/users',
      method: 'GET',
      params: {
        page: input.page,
        pageSize: input.pageSize,
        name: input.filters?.name,
        role: input.filters?.role,
        approvedAt: input.filters?.approvedAt,
        deletedAt: input.filters?.deletedAt,
      },
      revalidate: 60 * 60 * 4, // 4 hours
    })

    return response
  }
}
