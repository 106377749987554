import { User } from '@/domain/entities'
import { HttpClient } from '@/data/protocols/http'
import { IUpdateUserUseCase } from '@/domain/usecases/users'
import { UseCaseErrorHandlerDecorator } from '@/presentation/decorators'

@UseCaseErrorHandlerDecorator()
export class UpdateUserUseCase implements IUpdateUserUseCase {
  constructor(private readonly http: HttpClient) {}

  async execute(
    input: IUpdateUserUseCase.Input,
  ): Promise<HttpClient.Output<IUpdateUserUseCase.Output>> {
    const response = await this.http.on<User>({
      url: `http://localhost:3001/users/${input.userId}`,
      method: 'PUT',
      body: {
        name: input.toUpdate.name,
        photoUrl: input.toUpdate.photoUrl,
        role: input.toUpdate.role,
      },
    })

    return response
  }
}
