'use client'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/navigation'

import { Logo } from '@/presentation/components/shared'
import { LoginForm } from '@/presentation/components/pages/accounts/login'

import styles from './styles.module.scss'

export function LoginPage() {
  const t = useTranslations('pages.Login')
  const router = useRouter()

  const onSignIn = async () => {
    router.push('/')
  }

  return (
    <div>
      <header className={styles.header}>
        <Logo width={60} height={60} />

        <strong>{t('LOGIN_STEP_TITLE')}</strong>
      </header>

      <section className={styles.content}>
        <LoginForm callback={onSignIn} />
      </section>
    </div>
  )
}
